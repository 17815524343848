@import '../Variables/Variables.scss';

//Estilos gerais do elemento
.about-container {
  width: 100%;
  height: 70vh;

  p,
  h3 {
    text-shadow: $textShadow;
  }

  img {
    width: 70%;
    height: 90%;
  }
}

#profile {
  border-radius: 50%;
  width: 220px;
  height: 220px;
  margin: 25px;
  border: #ccc solid 5px;
  box-shadow: 5px 2px 20px 0 rgba(46, 61, 73, 0.5);
}