@import '../Variables/Variables.scss';

body {
  margin: 0 auto;
  font-family: $bodyFontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: $codeFontFamily;
}