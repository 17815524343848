@import '../Variables/Variables.scss';

#icons {
  height: 20vh;

  svg {
    width: 40px;
    height: fit-content;
    margin: 5px;
  }
}

p {
  margin: 5px;
}

#contact {
  font-size: 1rem;
  color: $lightColor;

  a {
    text-decoration: none;
    color: $lightColor;
    opacity: .7;
  }

  a:hover {
    opacity: 1;
  }
}