@import '..//Variables/Variables.scss';

/* Carousel styling */
#introCarousel,
.carousel-inner,
.carousel-item,
.carousel-item.active {
  width: 100%;
  margin: 0;
  height: 100vh;
}

.carousel-item:nth-child(1) {
  background-image: url($memoryFighter) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.carousel-item:nth-child(2) {
  background-image: url($travelsBook) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.carousel-item:nth-child(3) {
  background-image: url($myFlix) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/* Height for devices larger than 576px */
@media (min-width: 992px) {
  #introCarousel {
    margin-top: -58.59px;
  }
}

.navbar .nav-link {
  color: #fff !important;
}

//Estilos gerais do elemento
.carousel {
  @include carouselStyles(100%, 100vh, $textShadow, $bgColor);

  img {
    @include carouselImgStyle(100%, 100%, $bgColor, 60%);
  }
}

.btn {
  box-shadow: $boxShadow;
}

.carousel-item .carousel-caption,
.carousel-caption {
  top: 30%;
}

@media (min-width: 468) {
  img {
    object-fit: auto;
  }
}