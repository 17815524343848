@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
body{margin:0 auto;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}
#app{width:100%;margin:0;height:auto}#form{width:100%;height:100vh;margin:auto auto}#skills{width:100%;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;display:flex}#carousel{width:100%;height:90vh;text-shadow:none;background-color:auto}#carousel div{margin:auto}footer{width:100%;height:20vh;height:auto;display:flex}main{z-index:2}section,footer{z-index:3}@media(min-width: 468px){footer{width:250px;height:auto}#carousel{height:100vh}}
.navbar{width:100vw;position:fixed;z-index:3;top:0;left:0}.navbar .navbar-brand{font-family:"Roboto",sans-serif;font-size:2rem;margin-left:10px;color:#ccc;font-weight:bolder;text-shadow:2px 2px 2px rgba(0,0,0,.84)}.navbar .navbar-brand span{color:aqua}
#introCarousel,.carousel-inner,.carousel-item,.carousel-item.active{width:100%;margin:0;height:100vh}.carousel-item:nth-child(1){background-image:url(/static/media/memoryfighter.25d3d4cf.PNG) !important;background-repeat:no-repeat;background-size:cover;background-position:center center}.carousel-item:nth-child(2){background-image:url(/static/media/travelsbook.99ee814a.PNG) !important;background-repeat:no-repeat;background-size:cover;background-position:center center}.carousel-item:nth-child(3){background-image:url(/static/media/myflix.f6979581.PNG) !important;background-repeat:no-repeat;background-size:cover;background-position:center center}@media(min-width: 992px){#introCarousel{margin-top:-58.59px}}.navbar .nav-link{color:#fff !important}.carousel{width:100%;height:100vh;text-shadow:2px 2px 2px rgba(0,0,0,.84);background-color:#000}.carousel img{width:100%;height:100%;background-color:#000;-webkit-filter:opacity(60%);filter:opacity(60%)}.btn{box-shadow:6px 6px 16px -3px rgba(0,0,0,.84)}.carousel-item .carousel-caption,.carousel-caption{top:30%}@media(min-width: 468){img{object-fit:auto}}
#contato{margin-top:auto auto;width:100%}
.description{display:block}.card{width:270px;height:360px;margin:0 25px;transform:scale(0.9);transition:all .2s ease-out}.card:hover{transform:scale(1.01)}@media(min-width: 468){.card-desktop{width:350px}}
#projetos{margin:0 auto;display:flex;justify-content:space-between;align-items:center;flex-flow:row wrap;width:95vw;height:auto;text-shadow:none;background-color:2px 2px 2px rgba(0,0,0,.84)}.carousel-desktop,.carousel-desktop div,.card-desktop{opacity:0}@media(min-width: 468px){.carousel-desktop,.carousel-desktop div,.card-desktop{opacity:1}.carousel-desktop{padding:5px}.carousel-desktop div{padding:5px}.card-desktop{width:350px}.carousel-mobile,.carousel-mobile div,.card-mobile{display:none}}
.container{width:90%;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.skillIcon{width:80px;height:auto;margin:10px}@media(min-width: 468px){.skillIcon{width:120px;height:auto}.container{width:60%;height:80vh}}
.about-container{width:100%;height:70vh}.about-container p,.about-container h3{text-shadow:2px 2px 2px rgba(0,0,0,.84)}.about-container img{width:70%;height:90%}#profile{border-radius:50%;width:220px;height:220px;margin:25px;border:#ccc solid 5px;box-shadow:5px 2px 20px 0 rgba(46,61,73,.5)}
#icons{height:20vh}#icons svg{width:40px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;margin:5px}p{margin:5px}#contact{font-size:1rem;color:#ccc}#contact a{text-decoration:none;color:#ccc;opacity:.7}#contact a:hover{opacity:1}
