.container {
  width: 90%;
  height: fit-content;
}

.skillIcon {
  width: 80px;
  height: auto;
  margin: 10px;
}

@media (min-width: 468px) {
  .skillIcon {
    width: 120px;
    height: auto;
  }

  .container {
    width: 60%;
    height: 80vh;
  }
}