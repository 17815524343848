@import '..//Variables/Variables.scss';

#app {
  width: 100%;
  margin: 0;
  height: auto;
}

#form {
  width: 100%;
  height: 100vh;
  margin: auto auto;
}

#skills {
  width: 100%;
  height: fit-content;
  display: flex;
}

#carousel {

  //Estilos gerais do elemento
  @include carouselStyles(100%, 90vh, none, auto);

  div {
    margin: auto;
  }
}

footer {
  width: 100%;
  height: 20vh;
  height: auto;
  display: flex;
}

main {
  z-index: 2;
}

section,
footer {
  z-index: 3;
}

@media (min-width: 468px) {
  footer {
    width: 250px;
    height: auto;
  }

  #carousel {
    height: 100vh;
  }
}